@import url('https://fonts.googleapis.com/css2?family=Spline+Sans+Mono:wght@700&family=Spline+Sans:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  font-family: 'Spline Sans', sans-serif;
  font-family: 'Spline Sans Mono', monospace;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(150, 32, 19, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
