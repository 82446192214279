$wine-red: #962013;
$vibrant-red: #C0392B;
$gold-color: #CFA165;

.header {
    // background: $wine-red-contrast;
    opacity: 1;
    z-index: 50;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 10%;
    left: 0;
    padding: 0;
    margin: 0;
    transition: 1s all ease-in-out;
    display: flex;
    justify-content: end;
    align-items: center;
    nav {
        a {
            margin: 0 2rem;
            color: white;
            text-decoration: none;
            z-index: 60;
            &:hover {
                background-color: $gold-color;
                opacity: 1;
            }
        }
        
    }
    .navBtn {
        padding: 5px;
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
        color: white;
        visibility: hidden;
    }
    .responsiveNav {
        transform: none;
    }
    &:hover {
        background: rgba(150, 32, 19, 1);
    }
}

@media only screen and (max-width: 1024px) {
    .header {
        .navBtn {
            visibility: visible;
            opacity: 1;
            margin-right: 2rem;
        }
        nav {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
            background-color: $wine-red;
            transition: 1s;
            transform: translateY(-100vh);
            .navCloseBtn {
                position: absolute;
                top: 2rem;
                right: 2rem;
                margin-right: 0;
            }
            a {
                font-size: 1.5rem;
            }
        }
        .responsiveNav {
            transform: none;
        }
    }
}