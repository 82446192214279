* {
    box-sizing: border-box;
}
html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
$vibrant-red: #C0392B;
$wine-red: #962013;
.portfolio {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    scroll-behavior: smooth;
    .certificate {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // @media only screen and (max-width: 1200px) {
        //     // overflow: hidden;
        //     overflow: auto;
        //     img {
        //         box-sizing: border-box;
        //         // width: 300px;
        //         height: 70%;
        //         // width: 80%;
        //     }
        //     .horizontal {
        //         width: 70%;

        //     }
        //     .vertical {
        //         height: 70%;
        //     }
        // }
        overflow: auto;
            img {
                box-sizing: border-box;
                // width: 300px;
                height: 70%;
                // width: 80%;
            }
            .horizontal {
                width: 70%;

            }
            .vertical {
                height: 70%;
            }
    }
}