$wine-red: #962013;
$vibrant-red: #C0392B;

.canvas {
    height: 100%;
    position: relative;
    background: $wine-red;
}
.aboutSection {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    margin-top: 10%;
    background-color: $wine-red;
    @media only screen and (max-width: 1020px) {
        justify-content: flex-start;
        overflow: scroll;
    }
    @media only screen and (max-width: 760px) {
        justify-content: flex-start;
        overflow: scroll;
    }
    p {
        text-align: center;
    }
    h2 {
        text-align: center;
    }
    .aboutRow {
        box-sizing: border-box;
        width: 80%;
        display: flex;
        align-items: center;
        img {
            width: 250px;
            border-radius: 100%;
        }

        .content {
            box-sizing: border-box;
            padding: 15px;
            color: white;
            line-height: 2em;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            .skills {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .skill {
                    box-sizing: border-box;
                    margin: 0px 7px;
                    text-decoration: none;
                    color: white;
                    position: relative;
                    z-index: 1;
                    &::before {
                        content: '';
                        background-color: #cfa165;
                        position: absolute;
                        left: 0;
                        bottom: 3px;
                        width: 100%;
                        height: 8px;
                        z-index: -1;
                        transition: all .3s ease-in-out;
                    }
                    &:hover::before{
                        bottom: 0;
                        height: 100%;
                        visibility: visible;
                    }
                    
                }
            }
            a {
                color: white;
                text-align: center;
                margin: 5px 0px;
                background:
                  linear-gradient(
                    to right,
                    rgba(207, 161, 101, 1),
                    rgba(207, 161, 101, 1)
                  ),
                  linear-gradient(
                    to right,
                    rgba(102, 0, 37, 1),
                    rgba(130, 130, 130, 1),
                    rgba(207, 161, 101, 1),
                );
                background-size: 100% 3px, 0 3px;
                background-position: 100% 100%, 0 100%;
                background-repeat: no-repeat;
                transition: background-size 400ms;
                &:hover {
                    background-size: 0 3px, 100% 3px;
                }
            }
        }
        .sectionTitle {
            box-sizing: border-box;
            min-width: 200px;
            border-right: 1px solid white;
            padding: 5px;
            text-align: center;
            @media only screen and (max-width: 760px) {
                border: none;
            }
          
        }

        @media only screen and (max-width: 760px) {
            flex-direction: column;
            width: 100%;
        }
    }
}
